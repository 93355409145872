import React from "react"
import styled from "styled-components"
import {
  LinkedinShareButton,
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share"

import { device, colors } from "../../../styles/constants"
import { Link } from "gatsby"


/* Blog Post Footer from the Blog Post page */

class BlogPostFooter extends React.Component {
  render() {
    const shareUrl = `https://www.flipbase.com${this.props.location.pathname}`
    
    return (
      <ContentWrapper>
        <WrapperShare>
          <StyledLink to="/blog">
            <IconArrow src="/arrow.png" />
            <span>Back to overview</span>
          </StyledLink>
          <WrapperShareButtons>
            <ShareTitle>Share It</ShareTitle>
            <EmailShareButton url={shareUrl} >
              <img
                src="/email.svg"
                onMouseEnter={e => (e.currentTarget.src = "/email_color.svg")}
                onMouseLeave={e => (e.currentTarget.src = "/email.svg")}
              />
            </EmailShareButton>
            <FacebookShareButton url={shareUrl}>
              <img
                src="/fb_contact.svg"
                onMouseEnter={e =>
                  (e.currentTarget.src = "/fb_color_contact.svg")
                }
                onMouseLeave={e => (e.currentTarget.src = "/fb_contact.svg")}
              />
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl}>
              <img
                src="/ln_contact.svg"
                onMouseEnter={e =>
                  (e.currentTarget.src = "/ln_color_contact.svg")
                }
                onMouseLeave={e => (e.currentTarget.src = "/ln_contact.svg")}
              />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareUrl}>
              <img
                src="/wa.svg"
                onMouseEnter={e => (e.currentTarget.src = "/wa_color.svg")}
                onMouseLeave={e => (e.currentTarget.src = "/wa.svg")}
              />
            </WhatsappShareButton>
          </WrapperShareButtons>
        </WrapperShare>
      </ContentWrapper>
    )
  }
}




// position:fixed;
// bottom:0;
// box-shadow: 0 -4px 60px 0 rgba(0, 0, 0, 0.1);
const ContentWrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 80px;
  padding: 75px 0;
  background-color: #ffffff;
  z-index: 700;
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    padding: 32px;
  }
  @media ${device.mobileL} {
    margin-top: 0;
    padding: 32px 16px 64px 16px;
  }
  a {
    font-family: FreightSans;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: ${colors.dark};
    margin-bottom: 0;
    text-transform: uppercase;
    :hover {
      color: ${colors.dark};
    }
  }
`

const WrapperShare = styled.div`
  width: 100%;
  max-width: 785px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    outline: none;
    border: none;
  }
  img {
    margin-bottom: 0;
    margin-left: 16px;
  }
  @media ${device.tablet} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    max-width: 785px;
  }
`

const ShareTitle = styled.h4`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 30px;
  line-height: 39px;
  color: ${colors.dark};
  margin-bottom: 0;
  margin-right: 34px;
  @media ${device.mobileL} {
    font-size: 26px;
    line-height: 32px;
  }
`

const WrapperShareButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media ${device.tablet} {
    margin-top: 32px;
  }
  @media ${device.mobileL} {
    margin-top: 16px;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 260px;
  justify-content: flex-start;
  span {
    border-bottom: 1px solid #a62651;
    padding-bottom: 4px;
  }
`

const IconArrow = styled.img`
  transition: all 0.4s;
  margin-right: 18px;
  ${StyledLink}:hover & {
    margin-right: 24px;
    opacity: 0.7;
  }
`

export default BlogPostFooter
