import React from "react"
import { GatsbyLocation } from "local-types"
import SEO from "../components/Seo/Seo"
import Layout from "../components/Layout"
import { useSiteMetadata } from "../hooks"

import styled from "styled-components"
import { device, colors } from "../styles/constants"

import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"

import SectionLayout from "../components/WrapperSections/SectionLayout"
import BlogPostHeader from "../components/BlogPostComponents/BlogPostHeader"
import BannerBody from "../components/BlogPostComponents/BannerBody"
import GifBody from "../components/BlogPostComponents/GifBody"
import ModalHeader from "../components/Layout/ModalHeader"
import BlogPostFooter from "../components/BlogPostComponents/BlogPostFooter"

// Images
// Videos ERROR
// Quotes
// Unordered list
// Ordered list

const IntroHeader = styled.h3`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 30px;
  line-height: 39px;
  color: ${colors.dark};
  @media ${device.tablet} {
    font-size: 28px;
    line-height: 32px;
  }
  @media ${device.mobileL} {
    font-size: 24px;
    line-height: 26px;
  }
`

const SubHeader = styled.h4`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.dark};
  @media ${device.tablet} {
    font-size: 24px;
    line-height: 28px;
  }
  @media ${device.mobileL} {
    font-size: 18px;
    line-height: 22px;
  }
`
const CTAHeader = styled(SubHeader)`
  margin-bottom: 25px;
`

const ParagraphHeader = styled.h6`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.dark};
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 20px;
  }
`

const Question = styled(ParagraphHeader)`
  font-style: italic;
`

const ParagraphPost = styled.p`
  font-family: FreightSans;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: rgba(35, 29, 48, 0.6);
  margin: 0px 0px 24px 0px;
  display: inline-block;
  width: 100%;
  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 24px;
  }
`

interface VideoIframeProps {
  embedLink: string
}

const VideoIframe: React.FunctionComponent<VideoIframeProps> = ({
  embedLink,
}): React.ReactElement => {
  return (
    <iframe
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      }}
      src={embedLink}
      frameBorder="0"
      allowFullScreen="allowfullscreen"
    ></iframe>
  )
}

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
`

interface VideoEmbedProps {
  embedLink: string
}

const VideoEmbed: React.FunctionComponent<VideoEmbedProps> = ({
  embedLink,
}): React.ReactElement => {
  return (
    <ParagraphPost>
      <VideoWrapper>
        <VideoIframe embedLink={embedLink} />
      </VideoWrapper>
    </ParagraphPost>
  )
}

const BigHeader = styled.h2`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 45px;
  line-height: 59px;
  color: ${colors.mainColor};
  @media ${device.tablet} {
    font-size: 36px;
    line-height: 38px;
  }
  @media ${device.mobileL} {
    font-size: 28px;
    line-height: 32px;
  }
`

const shortcodes = {
  Link,
  BannerBody,
  IntroHeader,
  SubHeader,
  ParagraphHeader,
  Question,
  ParagraphPost,
  CTAHeader,
  GifBody,
  VideoEmbed,
  BigHeader,
  ModalHeader,
}

interface PostPageLayoutPageProps {
  location: GatsbyLocation
  data: object
}

const PostPageLayout: React.FunctionComponent<PostPageLayoutPageProps> = ({
  location,
  data,
}): React.ReactElement => {
  const { title: siteTitle } = useSiteMetadata()
  const blogFrontmatter = data.mdx.frontmatter

  // Once we have all the keywords for each blog we can remove this
  const generalBlogMetadata = {
    Keywords: ["Flipbase blog", "Flipbase customer stories"],
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={blogFrontmatter.title}
        description={blogFrontmatter.seoDescription}
        keywords={blogFrontmatter.seoKeywords || generalBlogMetadata.Keywords}
        image={blogFrontmatter.seoImage}
      />
      <BlogPostHeader location={location} data={data} />
      <PostWrapper>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </MDXProvider>
      </PostWrapper>
      <BlogPostFooter location={location} data={data} />
    </Layout>
  )
}

const PostWrapper = styled.div`
  margin: 24px auto;
  max-width: 825px;
  background: #fff;
  border-radius: 10px;
  padding: 40px 28px 40px;
  z-index: 602;
  @media ${device.tablet} {
    margin: 24px 32px 24px;
  }
  @media ${device.mobileL} {
    margin-top: 128px;
    margin-bottom: 220px;
  }
  p {
    img {
      margin: 20px auto 0px auto;
      display: block;
    }
  }
`

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
        category
        seoKeywords
        seoDescription
        seoImage
        featuredImage {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default PostPageLayout
