import React from "react"
import styled from "styled-components"

import { device, colors } from "../../../styles/constants"
import FlipbaseIcons from "../../DecorationElements/FlipbaseIcons"
import { Link } from "gatsby"

/* Banner from the Post Body*/

class BannerBody extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <FlipbaseIcons
          type="quotes"
          bgColor="#ffffff"
          radiusIcon="3px"
          shadow="none"
        />
        <TextBanner>"{this.props.data}"</TextBanner>
      </ContentWrapper>
    )
  }
}

// border:1px solid rgba(35, 29, 48, 0.1);
const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 40px;
  justify-content: flex-start;
  @media ${device.tablet} {
    padding: 20px 32px;
    margin: 32px 0;
  }
  @media ${device.mobileL} {
    display: flex;
    flex-direction: row;
    margin: 24px 0;
    padding: 16px;
  }
  img {
    height: 100%;
  }
`

const TextBanner = styled.h5`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: ${colors.dark};
  margin-left: 96px;
  margin-bottom: 0;
  max-width: 400px;
  text-align: center;
  @media ${device.tablet} {
    margin-left: 32px;
  }
  @media ${device.mobileL} {
    font-size: 18px;
    line-height: 24px;
    margin-left: 0px;
  }
`

export default BannerBody
