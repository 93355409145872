import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { device, colors } from "../../../styles/constants"

/* Blog Post Header from the Blog Post page*/

class BlogPostHeader extends React.Component {
  render() {
    const headerData = this.props.data.mdx.frontmatter
    const shareUrl = `https://www.flipbase.com${this.props.location.pathname}`

    return (
      <ContentWrapper>
        <Overlay></Overlay>
        {headerData.featuredImage.childImageSharp !== null && (
          <Image
            imgStyle={{ objectFit: "contain" }}
            fluid={headerData.featuredImage.childImageSharp.fluid}
          />
        )}
        {headerData.featuredImage.childImageSharp == null && (
          <CoverGif src={`/${headerData.featuredImage.relativePath}`} />
        )}
        <BannerInfo>
          <TitleBanner>{headerData.title}</TitleBanner>
          <WrapperBannerFooter>
            {headerData.category !== undefined && (
              <CategoryIcon category={headerData.category}>
                {headerData.category}
              </CategoryIcon>
            )}
            {headerData.date !== undefined && (
              <CreatedDate>{headerData.date}</CreatedDate>
            )}
          </WrapperBannerFooter>
        </BannerInfo>
      </ContentWrapper>
    )
  }
}

const CoverGif = styled.img`
  width: 100%;
  height: 100%;
  max-height: 640px;
  object-fit: contain;
`
const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 80px;
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
  }
  @media ${device.mobileL} {
    margin-top: 0;
  }
  .gatsby-image-wrapper {
    height: 100%;
    max-height: 640px;
    object-fit: cover !important;
  }
  img {
    height: 100%;
    max-height: 640px;
    object-fit: cover !important;
  }
`

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  max-height: 640px;
  width: 100%;
  top: 0;
  left: 0;
  background: ${colors.dark};
  opacity: 0.3;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 600;
`

const BannerInfo = styled.div`
  position: absolute;
  width: 560px;
  margin-left: -280px;
  top: 60%;
  border-radius: 10px;
  padding: 40px 28px 40px;
  background-color: #ffffff;
  z-index: 601;
  left: 50%;
  text-align: left;
  @media ${device.tablet} {
    width: 700px;
    margin-left: -350px;
    top: 50%;
    padding: 26px 24px;
  }
  @media ${device.mobileL} {
    top: 90%;
    padding: 16px;
    width: 100%;
    margin-left: -50%;
  }
`

const TitleBanner = styled.h5`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 24px;
  color: ${colors.dark};
  @media ${device.laptopL} {
    font-size: 30px;
    line-height: 34px;
  }
  @media ${device.tablet} {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 16px;
  }
  @media ${device.mobileL} {
    font-size: 22px;
    line-height: 26px;
  }
`

const WrapperBannerFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const CreatedDate = styled.span`
  font-family: FreightSans;
  font-size: 14px;
  color: rgba(35, 29, 48, 0.4);
`

const CategoryIcon = styled.div`
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
  font-family: FreightSans;
  padding: 0 12px;
  text-shadow: none;
  margin-right: 32px;
  color: ${({ category }) => {
    if (category === "customer stories") return "#2D9CDB"
    if (category === "partnerships") return "#219653"
    if (category === "trends") return "#A62651"
    if (category === "flipbase innovations") return "#F2994A"
    if (category === "events") return "#f5b042"
  }};
  background-color: ${({ category }) => {
    if (category === "customer stories") return "rgba(45, 156, 219, 0.1)"
    if (category === "partnerships") return "rgba(33, 150, 83, 0.1)"
    if (category === "trends") return "rgba(166, 38, 81, 0.1)"
    if (category === "flipbase innovations") return "rgba(242, 153, 74, 0.1)"
    if (category === "events") return "rgba(245, 230, 66, 0.1)"
  }};
`

export default BlogPostHeader
