import React from "react"
import styled from "styled-components"

import { device } from "../../../styles/constants"



/* Image from the Post Body*/

class GifBody extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <img
          style={{ display: "block", margin: "16px auto", width:`${this.props.width}`, height:`${this.props.height}` }}
          src={this.props.src}
          alt={this.props.alt}
        />
      </ContentWrapper>
    )
  }
}


const ContentWrapper = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
`



export default GifBody
